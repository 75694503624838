import React from "react";
import Contactform from "./Contactform";
import Contactplace from "./Contactplace";

function Contactstock() {
  return (
    <>
      <div className="bg-layout drop-shadow-md lg:w-[152vh] lg:h-[77vh] lg:ml-[17rem] relative top-[6rem] swing-in-top-fwd">
        <div className="flex flex-row justify-center items-center lg:mt-[2rem] ">
          <Contactform />
          <Contactplace />
        </div>
      </div>
    </>
  );
}

export default Contactstock;
