import React from "react";
// css
import "./assets/css/Animation.css";
// router dom
import {
  createBrowserRouter,
  Route,
  createRoutesFromElements,
  RouterProvider,
} from "react-router-dom";
// Layout
import Mainlayout from "./Layout/Mainlayout";
// pages
import Homestock from "./pages/home/Homestock";
import Chat from "./pages/Chats/Chat";
import Termstock from "./pages/Terms&Service/Termstock";
import Privacypolicymain from "./pages/Privacypolicy/Privacypolicymain";
import Licensestock from "./pages/License/Licensestock";
import Helpmain from "./pages/Helpcenter/Helpmain";
import Referalstock from "./pages/Referal/Referalstock";
import Contactstock from "./pages/Contact/Contactstock";
import Walletstock from "./pages/Wallet/Walletstock";
import Addmoney from "./pages/Wallet/Transferfund/Addmoney";
import TransferMain from "./pages/Wallet/Rewards/TransferMain";
import WalletsMoney from "./pages/Wallet/Transferfund/WalletsMoney";
import WithdarwWallet from "./pages/Wallet/Withdrawfunds/WithdarwWallet";
import Withdrawtransfersuccess from "./pages/Wallet/Withdrawfunds/Withdrawtransfersuccess";
import ProductMainComponent from "./pages/home/ServicesByINO/FindProduct/ProductMainComponent";

// paths
const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Mainlayout />}>
      <Route index element={<Homestock />} />
      <Route path="/findproducts"  element={<ProductMainComponent/>} />
      <Route path="chat" element={<Chat />} />
      <Route path="referal" element={<Referalstock />} />
      <Route path="contact-us" element={<Contactstock />} />
      <Route path="TermsandService" element={<Termstock />} />
      <Route path="Privacy&Policy" element={<Privacypolicymain />} />
      <Route path="wallet" element={<Walletstock />} />
      <Route path="/wallet/walletrewards" element={<TransferMain />} />
      <Route path="/wallet/addmoney" element={<Addmoney />} />
      <Route path="License" element={<Licensestock />} />
      <Route path="Helpcenter" element={<Helpmain />} />
      <Route path="/wallet/userwallet" element={<WalletsMoney />} />
      <Route path="/wallet/withdraw" element={<WithdarwWallet />} />
      <Route
        path="/wallet/withdrawlsuccess"
        element={<Withdrawtransfersuccess />}
      />
    </Route>
  )
);

function Routes() {
  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}

export default Routes;
