import React from 'react'

function ProductsliderPost() {
  return (
    <div>
    post product component
    </div>
  )
}

export default ProductsliderPost
