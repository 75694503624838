import React from 'react'

function JobsPost() {
  return (
    <div>
      jobs post component
    </div>
  )
}

export default JobsPost
