import React from "react";
import home from "./staticimages/home.png";
import Explore from "./staticimages/explore.png";
import Jobs from "./staticimages/findjobs.png";
import Product from "./staticimages/addproduct.png";
import ervice from "./staticimages/listservice.png";
import Post from "./staticimages/jobpost.png";
import Member from "./staticimages/member.png";
import laptop from "./staticimages/laptop.png";
import { NavLink } from "react-router-dom";
function Services() {
  return (
    <>
      <div className="flex flex-col gap-[17px]">
        <div className="flex flex-row gap-4">

<NavLink to="/findproducts" >  <div className="bg-white shadow-l text-center lg:h-[183px] lg:w-[197px] rounded-md  cursor-pointer  transform hover:scale-105 duration-500 ease-in-out">
            <div className="img">
              <img src={home} alt="" />
            </div>
            <h3 className="text-center font-semibold font-all mt-[12px]">
              Find Product
            </h3>
          </div></NavLink>

        

          <div className="bg-white shadow-l text-center lg:h-[183px] lg:w-[197px] rounded-md  cursor-pointer  transform hover:scale-105 duration-500 ease-in-out">
            <div className="img">
              <img src={Explore} alt="" />
            </div>
            <h3 className="text-center font-semibold font-all mt-[12px]">
              Explore Service
            </h3>
          </div>

          <div className="bg-white shadow-l text-center lg:h-[183px] lg:w-[197px] rounded-md  cursor-pointer  transform hover:scale-105 duration-500 ease-in-out">
            <div className="img">
              <img src={Jobs} alt="" />
            </div>
            <h3 className="text-center font-semibold font-all mt-[12px]">
              Find Jobs
            </h3>
          </div>
        </div>

        <div className="flex flex-row gap-4">
          <div className="bg-white shadow-l text-center lg:h-[183px] lg:w-[197px] rounded-md  cursor-pointer  transform hover:scale-105 duration-500 ease-in-out">
            <div className="img">
              <img src={Product} alt="" />
            </div>
            <h3 className="text-center font-semibold font-all mt-[12px]">
              Add Project
            </h3>
          </div>

          <div className="bg-white shadow-l text-center lg:h-[183px] lg:w-[197px] rounded-md  cursor-pointer  transform hover:scale-105 duration-500 ease-in-out">
            <div className="img">
              <img src={ervice} alt="" />
            </div>
            <h3 className="text-center font-semibold font-all mt-[12px]">
              List Your Service
            </h3>
          </div>

          <div className="bg-white shadow-l text-center lg:h-[183px] lg:w-[197px] rounded-md  cursor-pointer  transform hover:scale-105 duration-500 ease-in-out">
            <div className="img">
              <img src={Post} alt="" />
            </div>
            <h3 className="text-center font-semibold font-all mt-[12px]">
              Post Job
            </h3>
          </div>
        </div>
        <div className="flex flex-row gap-[19px]">
          <div className=" text-center lg:h-[auto] lg:w-[406px] rounded-md">
            <div className="img">
              <img src={Member} className="rounded-t-md" alt="" />
            </div>
           <button className="bg-[#CB2229] text-white rounded-full font-all p-[6px] lg:mt-[1rem] w-[18rem]   transform hover:scale-105 duration-500 ease-in-out ">Login as Reseller</button>
          </div>
          <div className="bg-white shadow-l text-center lg:h-[183px] lg:w-[197px] rounded-md  cursor-pointer  transform hover:scale-105 duration-500 ease-in-out">
            <div className="img">
              <img src={laptop} alt="" />
            </div>
            <h3 className="text-center font-semibold font-all mt-[12px]">
              Post Project
            </h3>
          </div>
          
        </div>
        
      </div>
    </>
  );
}

export default Services;
