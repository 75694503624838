import React from 'react'
import ReferQRcode from './ReferQRcode'
import ReferOtp from "./ReferOtp"
function Referalstock() {
  return (
   <>
   <div className="flex flex-row justify-center bg-layout drop-shadow-md lg:w-[152vh] lg:ml-[17rem] relative top-[6rem] swing-in-top-fw gap-[9rem]">
    <ReferQRcode/>
    <ReferOtp/>
   </div>
   </>
  )
}

export default Referalstock
