import React from 'react'

function Profilemain() {
  return (
    <div>
      hello i profile
    </div>
  )
}

export default Profilemain
