import React from "react";

function Buttonstore() {
  return (
    <>
      <div className="bg-[#FAC8CA] w-[377px] p-[13px] text-center font-all font-semibold transform hover:scale-105 duration-500 ease-in-out hover:bg-[#CB2229]  hover:text-white hover:rounded-md cursor-pointer">
        Register Your Store With us
      </div>
    </>
  );
}

export default Buttonstore;
